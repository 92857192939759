import React from "react";
import "./signup.css";
//import logo from "../../assets/dashboard/botgauge-new-logo.svg";
import account from "../../assets/signup-assets/account.svg";
import organisation from "../../assets/signup-assets/organisation.svg";
import mail from "../../assets/signup-assets/Icon.svg";
import logo from "./NewLog.png";
import { useSelector } from "react-redux";
import { getCompanyIcon } from "../../utils/whitelabel";

const Signup = () => {
   const userPrivileges = useSelector(
      (state) => state.permissions.userPrivileges
   );
   return (
      <div className="parent-signup-container">
         <div className="left-div">
            <div className="text-container">
               <div className="big-tagline">
                  Automate Your <br />
                  Web App Testing
               </div>
               <div className="smaller-tagline">
                  Ultimate automatic testing framework tailored for web apps
                  across multiple browsers.
                  <br /> Ensure consistency, efficiency, and quality like never
                  before.
               </div>
            </div>
         </div>
         <div className="right-div">
            <div className="register-form">
               <div className="logo-container">
                  <img
                     src={getCompanyIcon(userPrivileges.whitelabel)}
                     style={{ width: "143px" }}
                  />
                  <div className="welcome-text">
                     Welcome <span className="span-exc">!</span>
                  </div>
               </div>

               <div className="input-form">
                  <div className="input-a">
                     <div className="desc">First Name</div>
                     <div className="actual-input">
                        <img className="img-in" src={account} />
                        <input className="input-cont-val" placeholder="John" />
                     </div>
                     <div></div>
                  </div>
                  <div className="input-a">
                     <div className="desc">Last Name</div>
                     <div className="actual-input">
                        <img className="img-in" src={account} />
                        <input
                           className="input-cont-val"
                           placeholder="Kennedy"
                        />
                     </div>
                     <div></div>
                  </div>
                  <div className="input-a">
                     <div className="desc">Company Name</div>
                     <div className="actual-input">
                        <img className="img-in" src={organisation} />
                        <input
                           className="input-cont-val"
                           placeholder="Company Email"
                        />
                     </div>
                     <div></div>
                  </div>
                  <div className="input-a">
                     <div className="desc">Company Email</div>
                     <div className="actual-input">
                        <img className="img-in" src={mail} />
                        <input
                           className="input-cont-val"
                           placeholder="Ex:abc@gmail.com"
                        />
                     </div>
                     <div></div>
                  </div>
                  <div className="input-a">
                     <div className="desc">Role</div>
                     <div className="actual-input">
                        <select className="input-cont-val" name="userType">
                           <option value="admin">Admin</option>
                           <option value="user">User</option>
                        </select>
                     </div>
                     <div></div>
                  </div>
                  <button className="register-button"> SIGNUP</button>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Signup;
