// apiservices.js
import axios from "axios";
import publicRoutes from "../utils/publicRoutes";

// export const apiWS = "app.botgauge.com";
export const apiWS = window.location.hostname;
const wsProtocol = window.location.protocol;

export const apiUrl = `${wsProtocol}//${apiWS}`;
// export const apiUrl = process.env.REACT_APP_API_URL;
// export const apiUrl = "https://dev2.botgauge.xyz";
// export const apiWS = "dev2.botgauge.xyz";

const apiService = axios.create({
   baseURL: apiUrl,
   timeout: 50000,
   headers: {
      "Content-Type": "application/json", // Add Content-Type header for JSON requests
   },
});

export const fileService = axios.create({
   baseURL: apiUrl,
   timeout: 120000,
   headers: {
      "Content-Type": "multipart/form-data", // Add Content-Type header for JSON requests
   },
});

const getAuthConfig = (config) => {
   const token = localStorage.getItem("authToken");
   //const token = 'xyz';
   if (token) {
      config.headers.Authorization = `Bearer ${token}`;
   }
   return config;
};

fileService.interceptors.request.use((config) => {
   return getAuthConfig(config);
});

export const uploadFileForAI = async (formData) => {
   try {
      const response = await fileService.post(
         "/platform/create_testcase_with_ai/",
         formData,
         {
            headers: {
               "Content-Type": "multipart/form-data", // Ensure FormData is sent correctly
            },
         }
      );
      return response.data;
   } catch (error) {
      throw error.response.data || error;
   }
};
// Request interceptor
// Add default headers (e.g., authorization token)
apiService.interceptors.request.use((config) => {
   if (config.signal) {
      const source = axios.CancelToken.source();

      config.cancelToken = source.token;

      // When the signal aborts, cancel the request
      config.signal.addEventListener("abort", () => {
         source.cancel("Request cancelled");
      });
   }

   return getAuthConfig(config);
});

// Response interceptor
const isPublicRoute = publicRoutes.some(route => window.location.pathname.includes(route));
apiService.interceptors.response.use(
   (response) => {
      return response;
   },
   (error) => {
      // error.code === "ERR_NETWORK" ||
      if (error.response && error.response.status === 401 && !isPublicRoute) {
         // Only redirect if NOT on a public route
         localStorage.setItem("path", window.location.pathname);
         localStorage.removeItem("authToken");
         window.location.href = "/login";
      }
      return Promise.reject(error);
   }
);

export const endpoints = {
   getAllTestCases: "/platform/v1/get_all_testcases/",
   // Add other endpoints here as needed
};

export default apiService;

// const login = async () => {
//   try {
//     const response = await apiService.post("/login", {
//       username: "naresh@botgauge.com",
//       password: "A9$dFg!72@tL0xQ",
//     });
//     const token = localStorage.getItem("authToken");
//     authToken = response.data.token;
//     apiService.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   } catch (error) {
//     console.error("Login failed:", error);
//   }
// };

// // Initialize by logging in
// login();
