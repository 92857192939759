import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import { cn } from "../../utils/classModifier";
import './EllipsisOverflow.css';

const EllipsisOverflow = ({ children, className }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const isContentOverflowing = containerRef.current.scrollWidth > containerRef.current.clientWidth;
        setIsOverflowing(isContentOverflowing);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [children]);

  const getContent = () => {
    if (!children) return '';
    if (typeof children === 'string') return children;
    
    // Handle array of children
    if (Array.isArray(children)) {
      return children
        .map(child => {
          if (typeof child === 'string') return child;
          if (child?.props?.children) return child.props.children;
          return '';
        })
        .join('');
    }
    
    if (children.props && children.props.children) {
      if (typeof children.props.children === 'string') return children.props.children;
      if (Array.isArray(children.props.children)) {
        return children.props.children
          .map(child => (typeof child === 'string' ? child : ''))
          .join('');
      }
    }
    
    return '';
  };
  
  return (
    <Tooltip title={isOverflowing ? getContent() : ""} arrow placement="top">
      <div ref={containerRef} className={cn(`ellipsis-container`, className)}>
        {children}
      </div>
    </Tooltip>
  );
};

export default EllipsisOverflow;
