import React, { useState, useEffect } from "react";

const ClickPositionTracker = () => {
   const [clickInfo, setClickInfo] = useState(null);

   // Helper function to get absolute position in top window
   const getAbsolutePositionInTopWindow = (el) => {
      let x = 0,
         y = 0;
      let currentEl = el;
      let currentWin = el.ownerDocument.defaultView;

      while (currentEl) {
         const rect = currentEl.getBoundingClientRect();
         x += rect.left;
         y += rect.top;

         if (currentWin !== window.top && currentWin.frameElement) {
            currentEl = currentWin.frameElement;
            currentWin = currentWin.parent;
         } else {
            x += currentWin.pageXOffset;
            y += currentWin.pageYOffset;
            break;
         }
      }
      return { x, y };
   };

   const handleClick = (e) => {
      const el = e.target;
      const rect = el.getBoundingClientRect();
      const { x: globalLeft, y: globalTop } =
         getAbsolutePositionInTopWindow(el);

      // Get viewport dimensions
      const vw = window.innerWidth;
      const vh = window.innerHeight;

      // Calculate percentages
      const leftPercent = vw ? (globalLeft / vw) * 100 : 0;
      const topPercent = vh ? (globalTop / vh) * 100 : 0;
      const widthPercent = vw ? (rect.width / vw) * 100 : 0;
      const heightPercent = vh ? (rect.height / vh) * 100 : 0;

      setClickInfo({
         element: el.tagName.toLowerCase(),
         absolute: {
            x: Math.round(globalLeft),
            y: Math.round(globalTop),
            width: Math.round(rect.width),
            height: Math.round(rect.height),
         },
         percentage: {
            left: leftPercent.toFixed(2),
            top: topPercent.toFixed(2),
            width: widthPercent.toFixed(2),
            height: heightPercent.toFixed(2),
         },
      });
   };

   useEffect(() => {
      console.log("Click info updated:", clickInfo);
   }, [clickInfo]);

   return (
      <div
         style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#f7fafc",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
         }}
      >
         <div
            style={{
               width: "100%",
               height: "100%",
               cursor: "pointer",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
            }}
            onClick={handleClick}
         >
            <div>Click anywhere in this area</div>
         </div>

         <p onClick={handleClick}>apple</p>
         <p onClick={handleClick}>apple2</p>
         {clickInfo && (
            <div
               style={{
                  position: "absolute",
                  top: "1rem",
                  left: "1rem",
                  backgroundColor: "white",
                  padding: "1rem",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
               }}
            >
               <div>
                  <h2>Click Position Information</h2>
               </div>
               <div style={{ marginTop: "1rem" }}>
                  <div>
                     <h3 style={{ fontWeight: "500", marginBottom: "0.5rem" }}>
                        Element Information
                     </h3>
                     <p>Clicked element: {clickInfo.element}</p>
                  </div>

                  <div style={{ marginTop: "1rem" }}>
                     <h3 style={{ fontWeight: "500", marginBottom: "0.5rem" }}>
                        Absolute Position (pixels)
                     </h3>
                     <p>X: {clickInfo.absolute.x}px</p>
                     <p>Y: {clickInfo.absolute.y}px</p>
                     <p>Width: {clickInfo.absolute.width}px</p>
                     <p>Height: {clickInfo.absolute.height}px</p>
                  </div>

                  <div style={{ marginTop: "1rem" }}>
                     <h3 style={{ fontWeight: "500", marginBottom: "0.5rem" }}>
                        Relative Position (viewport %)
                     </h3>
                     <p>Left: {clickInfo.percentage.left}%</p>
                     <p>Top: {clickInfo.percentage.top}%</p>
                     <p>Width: {clickInfo.percentage.width}%</p>
                     <p>Height: {clickInfo.percentage.height}%</p>
                  </div>
               </div>
            </div>
         )}
      </div>
   );
};

export default ClickPositionTracker;

// const abc = (el) => {
//     const rect = el.getBoundingClientRect();
//     const { x: globalLeft, y: globalTop } = getAbsolutePositionInTopWindow(el);

//     // (E) Build the opening tag
//     const tagName = el.tagName.toLowerCase();
//     const topWindow = window.top;
//     // If you're in the same origin, topWindow.innerWidth/innerHeight is accessible
//     const vw = topWindow.innerWidth;
//     const vh = topWindow.innerHeight;

//     // Avoid division by zero in corner cases
//     const leftPercent = vw ? (globalLeft / vw) * 100 : 0;
//     const topPercent = vh ? (globalTop / vh) * 100 : 0;
//     const widthPercent = vw ? (rect.width / vw) * 100 : 0;
//     const heightPercent = vh ? (rect.height / vh) * 100 : 0;

//         console.log({
//             "leftPercent":leftPercent,
//             "topPercent" :topPercent,
//             "widthPercent":widthPercent,
//             "heightPercent":heightPercent
//         })
// }
