import React, { useState, useEffect } from "react";
import heye from "../../assets/eye-open.svg";
import seye from "../../assets/eye-close.svg";
import "./login.css";
import { loginUser } from "../../services/testCaseServices";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import {
   set_presence_ai_groups,
   set_presence_ai_testcases,
} from "../../redux/actions/presenceAiAction";
import { getCompanyIcon } from "../../utils/whitelabel";
import BaseForm from "../../ui/form/BaseForm"; 

const LoginPage = ({
   companyName,
   userPrivileges,
   set_presence_ai_testcases,
   set_presence_ai_groups,
}) => {

   const [errorMsg, setErrorMsg] = useState(null);
   const navigate = useNavigate();
   const { login, authToken } = useAuth();
   const [submitting, setSubmitting] = useState(false);

   useEffect(() => {
      if (authToken) {
         navigate("/test-case", { replace: true });
      }
   }, [authToken, navigate]);

   const onSubmit = async (data) => {
      setErrorMsg(""); 
      setTimeout(() => setErrorMsg(null), 5000); 

      const data_to_send = {
         email: data.email,
         password: data.password,
      };

      setSubmitting(true);

      try {
         const response = await loginUser(data_to_send);
         if (response && response.token) {
            login(response.token);
            set_presence_ai_testcases([]);
            set_presence_ai_groups([]);
            if (localStorage.getItem("authToken")) {
               if (localStorage.getItem("path")) {
                  navigate(localStorage.getItem("path"));
                  localStorage.removeItem("path");
               } else {
                  navigate("/test-case");
               }
            }
         } else {
            setErrorMsg("Invalid email or password.");
            setTimeout(() => setErrorMsg(null), 5000); 
         }
      } catch (error) {
         console.error("Login failed:", error);
         setErrorMsg(
            "Please check your username and password. If you still can't log in, contact your BotGauge support team."
         );
         setTimeout(() => setErrorMsg(null), 5000); 
      } finally {
         setSubmitting(false);
      }
   };

   const hostname = window.location.hostname;
   const companyLogo = hostname.includes("tsg")
      ? getCompanyIcon("tsg", "login")
      : companyName && companyName !== ""
         ? getCompanyIcon(companyName, "login")
         : getCompanyIcon(userPrivileges.whitelabel, "login");

   return (
      <div className="relative w-[100vw] h-[100vh] flex justify-center items-center bg-primary-50">

         <div className="absolute top-16 left-16">
            <img src={companyLogo} alt="logo" className="w-[10.875rem]" />
         </div>

         <div className="flex items-center flex-col w-[37.5rem] bg-white rounded-lg box-border">
            <div className="h-[6.25rem] w-full border-b border-b-secondary-200 flex items-center justify-start px-10 box-border">
               <h2 className="text-secondary-800 font-semibold text-2xl">Sign in</h2>
            </div>
            <div className="w-full flex flex-col items-center justify-center p-10 box-border">
               <BaseForm
                  inputs={[
                     { name: "email", type: "email", placeholder: "Ex. Ilovebotgauge@gmail.com", label: "Email" },
                     { name: "password", type: "password", placeholder: "Password", label: "Password" },
                  ]}
                  onSubmit={onSubmit}
                  submitting={submitting}
                  submitButtonText="LOGIN"
               />
               {errorMsg && (
                  <p className="text-sm text-red-400 mt-4">{ errorMsg }</p>
               )

               }
               <div className="mt-6 w-full h-8 flex items-center gap-2 font-medium text-md">
                  <p className=" text-secondary-700">Need an account?</p>
                  <a target="_blank" href="https://calendly.com/botgauge/30min" className="text-primary">Schedule a demo</a>
               </div>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = (state) => ({
   userPrivileges: state.permissions.userPrivileges,
});

const mapDispatchToProps = {
   set_presence_ai_testcases,
   set_presence_ai_groups,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
