export const addCard = (cardDetails, includePaste = false) => ({
   type: "ADD_CARD",
   payload: {
      ...cardDetails,
      ...(includePaste ? { paste: 5 } : {}),
   },
});

export const resetCards = () => ({
   type: "RESET_CARDS",
});

export const updateCard = (
   newText,
   id,
   newData,
   newstatus = 6,
   ordinal = null,
   do_ordinal_selection = null,
   newField3 = null
) => ({
   type: "UPDATE_CARD",
   payload: {
      step_id: id,
      instr: newText,
      data: newData,
      status: newstatus,
      ordinal: ordinal,
      do_ordinal_selection: do_ordinal_selection,
      newField3: newField3,
   },
});

export const editCard = (cardDetails) => ({
   type: "EDIT_CARD",
   payload: cardDetails,
});

export const deleteCard = (id) => ({
   type: "DELETE_CARD",
   payload: { id },
});

export const deleteCardSubsteps = (step_id) => ({
   type: "DELETE_CARD_SUBSTEPS",
   payload: { step_id },
});

export const setStatusMessage = (message) => ({
   type: "SET_STATUS_MESSAGE",
   payload: message,
});

export const setConditionActive = (condition) => ({
   type: "SET_CONDITION_ACTIVE",
   payload: condition,
});

export const setAllConditionsActive = (condition) => ({
   type: "SET_ALL_CONDITION_ACTIVE",
   payload: condition,
});

export const popConditionActive = () => ({
   type: "POP_CONDITION_ACTIVE",
});

export const popAllConditionActive = () => ({
   type: "POP_ALL_CONDITION_ACTIVE",
});

export const setLinkedLocalDataId = (Id) => ({
   type: "SET_LINKED_LOCAL_DATA_ID",
   payload: Id,
});

export const setCurrentTestcaseName = (Id) => ({
   type: "SET_CURRENT_TESTCASE_NAME",
   payload: Id,
});

export const setExistingGroups = (groups) => ({
   type: "SET_EXISTING_GROUPS",
   payload: groups,
});

export const setSessionIdForNewTC = (sessionId) => ({
   type: "SET_SESSION_ID_FOR_NEW_TC",
   payload: sessionId,
});

export const addToSelectedCards = (step_id) => ({
   type: "ADD_TO_SELECTED_CARD",
   payload: step_id,
});

export const removeSelectedCard = (step_id) => ({
   type: "REMOVE_SELECTED_CARD",
   payload: step_id,
});

export const removeAllSelectedCard = () => ({
   type: "REMOVE_ALL_SELECTED_CARDS",
});

export const setGroupPossibleForSelectedCards = (value) => ({
   type: "IS_GROUP_POSSIBLE_FOR_SELECTED_CARDS",
   payload: value,
});

export const setLoopPossibleForSelectedCards = (value) => ({
   type: "IS_LOOP_POSSIBLE_FOR_SELECTED_CARDS",
   payload: value,
});

export const setDeletePossibleForSelectedCards = (value) => ({
   type: "IS_DELETE_POSSIBLE_FOR_SELECTED_CARDS",
   payload: value,
});

export const setGroupingSelectedCards = (status) => ({
   type: "GROUPING_SELECTED_CARDS",
   payload: status,
});

export const setLoopingSelectedCards = (status) => ({
   type: "LOOPING_SELECTED_CARDS",
   payload: status,
});

export const setIfSelectedCards = (status) => ({
   type: "SET_IF_SELECTED_CARDS",
   payload: status,
});

export const setExitingIfelse = (stepId) => ({
   type: "SET_EXITING_IFELSE",
   payload: stepId,
});

// undo redo actions
export const addRecentAction = (action) => ({
   type: "ADD_RECENT_ACTION",
   payload: action,
});

export const addUndoedAction = (action) => ({
   type: "ADD_UNDOED_ACTION",
   payload: action,
});

export const popRecentAction = () => ({
   type: "POP_RECENT_ACTION",
});

export const popUndoedAction = () => ({
   type: "POP_UNDOED_ACTION",
});

export const popNumberOfRecentAction = (action) => ({
   type: "POP_NUMBER_OF_RECENT_ACTION",
   payload: action,
});

export const popAllUndoedActions = () => ({
   type: "POP_ALL_UNDOED_ACTIONS",
});

export const popAllRecentActions = () => ({
   type: "POP_ALL_RECENT_ACTIONS",
});

export const updateScreenshotStep = (step) => ({
   type: "SCREENSHOT_STEP",
   payload: step,
});

export const updateIsExpandedStep = (stepId) => ({
   type: "IS_EXPANDED_STEP",
   payload: stepId,
});

export const updateRecentStep = (stepId) => ({
   type: "UPDATE_RECENT_STEP",
   payload: stepId,
});

export const clearDeletedStepId = () => ({
   type: "CLEAR_DELETED_STEP_ID",
});

export const updateCustomInstruction = (instruction) => ({
   type: "UPDATE_CUSTOM_INSTRUCTION",
   payload: instruction,
});

export const setActionDisability = (option) => ({
   type: "SET_ACTION_DISABILITY",
   payload: option,
});

export const cloneGroupDetails = (details) => ({
   type: "CLONE_GROUP_DETAILS",
   payload: details,
});

export const showLabelOrdinalForCard = (step_id) => ({
   type: "SHOW_LABEL_ORDINAL_FOR_CARD",
   payload: step_id,
});

export const setAllTextsForLabelOrdinal = (array) => ({
   type: "SET_ALL_TEXTS_FOR_LABEL_ORDINAL",
   payload: array,
});

export const setLocalDataType5 = (array) => ({
   type: "SET_LOCAL_DATA_TYPE_5",
   payload: array,
});

// Hard warning functions
export const setHwActiveAction = (action) => ({
   type: "SET_HW_ACTIVE_ACTION",
   payload: action,
});
export const setHwCards = (array) => ({
   type: "SET_HW_CARDS",
   payload: array,
});
export const hwEditCard = (card, id) => ({
   type: "HW_EDIT_CARD",
   payload: { card, id },
});
export const setHwEditingStepOn = (id) => ({
   type: "SET_HW_EDITING_STEP_ON",
   payload: id,
});
export const setHwModalForCard = (card) => ({
   type: "SET_HW_MODAL_FOR_CARD",
   payload: card,
});
export const setHWSelectedSuggestion = (suggestion) => ({
   type: "SET_HW_SELECTED_SUGGESTION",
   payload: suggestion,
});
export const clearHwStepDetails = () => ({
   type: "CLEAR_HW_STEP_DETAILS",
});
// export const updateHwCard = (card) => ({
//    type: "UPDATE_HW_CARD",
//    payload: card,
// });
export const setHWSelectedCard = (card) => ({
   type: "SET_HW_SELECTED_CARD",
   payload: card,
});
export const setHWCardStatus = (cardDetails) => ({
   type: "SET_HW_CARD_STATUS",
   payload: cardDetails,
});
export const setHwLoader = (id) => ({
   type: "SET_HW_LOADER",
   payload: id,
});

export const setHwScreenshotRectangles = (array) => ({
   type: "SET_HW_SCREENSHOT_RECTANGLES",
   payload: array,
});
