import React, { useState } from "react";
import { useForm } from "react-hook-form";
import heye from "../../assets/eye-open.svg";
import seye from "../../assets/eye-close.svg";
import BaseButton from "../buttons/BaseButton";
import { CircularProgress } from "@mui/material";

const BaseForm = ({ 
    inputs, 
    onSubmit, 
    submitting, 
    submitButtonText 
}) => {
   const [showPassword, setShowPassword] = useState(false);
   const { register, handleSubmit, watch, formState: { errors } } = useForm();
   const password = watch("password");

   const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
   };

   return (
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
         {inputs.map((input, index) => (
            <div key={index} className={`flex flex-col gap-3 ${index !== 0 ? 'mt-5' : ''}`}>
               <label className="font-medium text-md text-secondary-900">{input.label}</label>
               {input.type === "password" || input.type === "retypePassword" ? (
                  <div className="password-container">
                     <input
                        className="input-password"
                        type={showPassword ? "text" : "password"}
                        placeholder={input.placeholder}
                        {...register(input.name, { 
                           required: `${input.label} is required`,
                           validate: input.type === "retypePassword" ? value => value === password || "Passwords do not match" : undefined
                        })}
                     />
                     <BaseButton
                        variant="ghost"
                        type="button"
                        onClick={togglePasswordVisibility}
                        className={"password-toggle w-6 h-6 flex items-center justify-center cursor-pointer"}
                     >
                        <img
                           className="absolute"
                           src={showPassword ? seye : heye}
                           alt="toggle-password-visibility"
                        />
                     </BaseButton>
                  </div>
               ) : (
                  <input
                     className="input-login box-border"
                     type={input.type}
                     placeholder={input.placeholder}
                     {...register(input.name, { required: `${input.label} is required` })}
                  />
               )}
               {errors[input.name] && <p className="error-message">{errors[input.name].message}</p>}
            </div>
         ))}
         {submitting ? (
            <BaseButton className={"w-full h-12 mt-8"} size={"lg"}>
               <CircularProgress className="!text-secondary-100" size={20} color="inherit" />
            </BaseButton>
         ) : (
            <BaseButton className={"w-full h-12 mt-8"} type="submit" size={"lg"}>
             <p className="login-click-text">{submitButtonText}</p>
            </BaseButton>
         )}
      </form>
   );
};

export default BaseForm;
