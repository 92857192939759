import React from "react";
import { cn } from "./../../utils/classModifier";
import "./button.css";

const BaseButton = ({
   as: Component = "button",
   variant = "solid",
   size = "sm",
   children,
   className,
   ...props
}) => {
   const variantStyles = {
      solid: "bg-primary text-white hover:bg-primary-600 active:bg-primary-700 disabled:bg-secondary-300 disabled:text-secondary-600",
      outline: "bg-white text-primary hover:bg-secondary hover:text-primary-600 active:bg-secondary-200 active:text-primary-700 border border-secondary-300 disabled:bg-secondary-300 disabled:text-secondary-600",
      ghost: "bg-transparent text-primary hover:bg-secondary hover:text-primary-600 active:bg-secondary-200 active:text-primary-700 disabled:bg-secondary-300 disabled:text-secondary-600",
      error: "bg-error text-white hover:bg-error-600 active:bg-error-700 disabled:bg-secondary-300 disabled:text-secondary-600",
      errorGhost: "bg-transparent text-error hover:bg-error-50 active:bg-error-100 disabled:bg-error-100 disabled:text-error-600",
      cancel: "bg-white text-secondary-800 hover:bg-secondary-100 active:bg-secondary-200 border border-secondary-300 disabled:bg-secondary-300 disabled:text-secondary-600"
   };

   const btnSize = {
      xs: "h-6 rounded-sm py-2 px-3 font-semibold text-xs leading-5 rounded-sm",
      sm: "h-7 rounded-sm py-xs px-3 font-semibold text-sm leading-5",
      md: "h-8 rounded-md py-xs px-4 font-semibold text-md leading-6",
      lg: "h-9 rounded-lg py-sm px-md font-semibold text-md leading-6",
   };

   return (
      <Component
         className={cn(
            "base-btn",
            variantStyles[variant],
            btnSize[size],
            className
         )}
         {...props}
      >
         {children}
      </Component>
   );
};

export default BaseButton;
