import React, { useEffect, useRef, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import ProgressBar from "../../ProgressBar/progressBar";
import "./topbar.css";
import { setCurrentTableName } from "../../../pages/Data/DataAction/dataAction";
import { toast } from "react-toastify";
import back from "../../../assets/back-icon.svg";
import editIcon from "../../../assets/pencil.svg";
import { rename_local } from "../../../services/dataService";
import { CircularProgress } from "@mui/material";
import ProfileDropdown from "../../ProfileDropdown/ProfileDropdown";
import {
   getTitle,
   addObserver,
   removeObserver,
   setTitle,
} from "../../../providers/titleProvider";
// import { TestcaseDetails } from "../../../services/testCaseServices";
// import { getSuiteDetailsById } from "../../../services/suitesServices";

const TopNav = ({ setCurrentTableName, currentTableName }) => {
   const inputRef = useRef(null);
   const location = useLocation();
   const navigate = useNavigate();
   const { id } = useParams(); // Extract the suite/localdata ID from the URL
   const [nameToShow, setNameToShow] = useState("");
   const {
      totalThread,
      allocatedCompilationThread,
      allocatedExecutionThread,
      dynamicThread,
      usedCompilationThread,
      usedExecutionThread,
   } = useSelector((state) => state.thread);

   const [showLocalDataHeader, setShowLocalDataHeader] = useState(false);
   const [editFileName, setEditFileName] = useState(false);
   const [fileName, setFileName] = useState("");
   const [settingFilename, setSettingFilename] = useState(false);

   const fetchData = () => {
      const pathnameSegments = location.pathname.split("/").filter(Boolean);
      const defaultName = pathnameSegments[pathnameSegments.length - 1].replace(
         /-/g,
         " "
      );

      if (pathnameSegments.length === 1) {
         setNameToShow(defaultName);
      } else if (
         pathnameSegments.length === 4 &&
         pathnameSegments[0] === "report"
      ) {
         setNameToShow(getTitle());
      } else {
         // need to check child routes
      }
   };

   useEffect(() => {
      fetchData();

      const titleObserver = () => {
         setNameToShow(getTitle(true));
      };
      addObserver(titleObserver);

      return () => {
         setCurrentTableName(null);
         setFileName(null);
         setNameToShow("");
         setTitle("", true);
         removeObserver(titleObserver);
      };
   }, [location.pathname, id]);

   useEffect(() => {
      setFileName(currentTableName);
   }, [currentTableName]);

   const handleBackToLocalData = () => {
      navigate("/data", {
         state: { triggerLocalActive: true },
      });
   };

   const handleEditFilename = () => {
      setEditFileName(true);
   };

   const handleRename = async () => {
      if (fileName.trim() === currentTableName) {
         setEditFileName(false);
         setFileName(currentTableName);
         return;
      } else if (fileName == "") {
         toast.error("File name cannot be empty");
         setFileName(currentTableName);
         setEditFileName(false);
         return;
      }

      try {
         setSettingFilename(true);
         const response = await rename_local(id, fileName.trim());
         if (response) {
            toast.success("File renamed successfully");
            setCurrentTableName(fileName.trim());
         }
      } catch (error) {
         toast.error("Error renaming file");
         setFileName(currentTableName);
      } finally {
         setEditFileName(false);
         setSettingFilename(false);
      }
   };

   return (
      <Navbar>
         <Container className="nav">
            {showLocalDataHeader ? (
               <div className="local-data-table-page-header">
                  <img
                     onClick={handleBackToLocalData}
                     src={back}
                     alt="Global data"
                  />
                  <span>Data</span>
                  <span style={{ margin: "0 4px" }}>/</span>
                  <div>
                     {editFileName ? (
                        <>
                           <input
                              className="edit-ld-filename-input"
                              autoFocus
                              ref={inputRef}
                              type="text"
                              value={fileName}
                              onChange={(e) => setFileName(e.target.value)}
                              onBlur={handleRename}
                              disabled={settingFilename}
                           />
                           {settingFilename && (
                              <span>
                                 <CircularProgress className="!text-primary !h-4.2 !w-4.2"
                                    // style={{
                                    //    width: "18px",
                                    //    height: "18px",
                                    //    color: "#1D86FF",
                                    // }}
                                 />
                              </span>
                           )}
                        </>
                     ) : (
                        <>
                           <p>{fileName}</p>
                           <img
                              src={editIcon}
                              alt="edit"
                              onClick={handleEditFilename}
                           />
                        </>
                     )}
                  </div>
               </div>
            ) : (
               <Navbar.Brand className="navtitle">{nameToShow}</Navbar.Brand>
            )}

            <div className="top-nav-right">
               <div className="top-nav-right-ce">
                  <div>
                     <p style={{ color: "#FF7A00" }}>Compilation</p>
                     <p style={{ fontSize: "12px" }}>
                        {usedCompilationThread}{" "}
                        {!dynamicThread && `/ ${allocatedCompilationThread}`}
                     </p>
                     <ProgressBar
                        fillColor="#FF7A00"
                        progress={
                           (usedCompilationThread * 100) /
                           allocatedCompilationThread
                        }
                     />
                  </div>
                  <div>
                     <p style={{ color: "#00299F" }}>Execution</p>
                     <p style={{ fontSize: "12px" }}>
                        {usedExecutionThread}{" "}
                        {!dynamicThread && `/ ${allocatedExecutionThread}`}
                     </p>
                     <ProgressBar
                        fillColor="#00299F"
                        progress={
                           (usedExecutionThread * 100) /
                           allocatedExecutionThread
                        }
                     />
                  </div>
               </div>
               <div className="ac">
                  <ProfileDropdown />
               </div>
            </div>
         </Container>
      </Navbar>
   );
};

const mapStateToProps = (state) => ({
   currentTableName: state.data.currentTableName,
});

const mapDispatchToProps = {
   setCurrentTableName,
};
export default connect(mapStateToProps, mapDispatchToProps)(TopNav);

// setTitle("", true);
// setNameToShow("");
// const fetchData = async () => {
//   const pathnameSegments = location.pathname.split("/").filter(Boolean);
//   const defaultName = pathnameSegments[pathnameSegments.length - 1].replace(
//     /-/g,
//     " "
//   );

//   console.log(pathnameSegments);

//   if (
//     pathnameSegments[0].toLowerCase() === "test-case" &&
//     pathnameSegments[1]
//   ) {
//     setNameToShow(getTitle());
//     // const testCaseId = pathnameSegments[1];
//     //fetchTestCaseName(testCaseId, defaultName);
//   } else if (pathnameSegments[0].toLowerCase() === "suites" && id) {
//     // fetchSuiteDetails(id, defaultName);
//   } else {
//     setNameToShow(defaultName);
//   }

//   if (pathnameSegments[0] === "Suites" && pathnameSegments[1]) {
//     setNameToShow(getTitle(true));
//   } else if (
//     pathnameSegments[0] === "suite-reports" &&
//     pathnameSegments[1]
//   ) {
//     setNameToShow(getTitle());
//   } else if (pathnameSegments[0] === "data" && pathnameSegments[1]) {
//     setShowLocalDataHeader(true);
//     setFileName(currentTableName);
//   } else {
//     setShowLocalDataHeader(false);
//   }
// };

// const fetchTestCaseName = async (testCaseId, defaultName) => {
//   try {
//     const data = await TestcaseDetails(testCaseId);
//     setNameToShow(data.name || defaultName);
//   } catch (error) {
//     console.error("Error fetching test case name:", error);
//     setNameToShow(defaultName);
//   }
// };

// const fetchSuiteDetails = async (suiteId, defaultName) => {
//   try {
//     const data = await getSuiteDetailsById(suiteId);
//     setNameToShow(data.description || defaultName);
//   } catch (error) {
//     console.error("Error fetching suite details:", error);
//     setNameToShow(defaultName);
//   }
// };
