import { SET_SHARABLE_ID } from "../constants/sharable-link-types";


const sharable_link = {
    sharable_id: null,
};

export const sharableLinkReducer = (state = sharable_link, action) => {
   switch (action.type) {
      case SET_SHARABLE_ID:
         return {
            ...state,
            sharable_id: action.payload,
         };

      default:
         return state;
   }
};

