import botgaugeGreenMini from "../assets/companyLogo/botgauge/botgauge-logo-mini.png";
import botgaugeGreenFull from "../assets/companyLogo/botgauge/botgauge-green-full.svg"; 
// import botgaugeBlue from "../assets/companyLogo/botgauge/logo-only-blue.png";
import tsg from "../assets/companyLogo/tsg/tsg.png";

export const getCompanyIcon = (company, component = "nav") => {
   switch (company) {
      case "botgauge":
         switch (component) {
            case "login":
               return botgaugeGreenFull;
            case "nav":
            case "loader":
            default:
               return botgaugeGreenMini;
         }
      case "tsg":
         return tsg;

      default:
         return botgaugeGreenMini;
   }
};

export const getCompanyName = (company) => {
   switch (company) {
      case "botgauge":
         return "BotGauge";
      case "tsg":
         return "TSG";

      default:
         return "BotGauge";
   }
};
